.updateUserDetails {
  .blockContent {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 36px;
    .inputBlock {
      margin-bottom: 20px;
      .selectWrap select,
      .inputWrap {
        width: 300px; }
      &.fullWidth {
        width: 100%;
        .inputWrap {
          width: 100%; } } } }

  .blockFooter {
    padding: 50px 0 40px 0;
    margin-top: 10px;
    border-top: 1px solid #E0E7F4;
    display: flex;
    align-items: center;
    justify-content: center; } }
