.fullPagePopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    .overlay {
        background: $black;
        opacity: 0.8;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: fixed; }
    .popupInner {
        background: $white;
        box-shadow: 4px 15px 20px rgba(0, 0, 0, 0.4);
        border-radius: 6px;
        padding: 30px;
        position: relative;
        width: 640px;
        max-height: 95vh;
        overflow-y: auto;
        .popupClose {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            svg {
                width: 20px;
                height: 20px;
                fill: $black; } }
        .popupTitle {
            @include font(18px, 24px, #3E3E4F);
            font-weight: bold;
            padding-bottom: 15px;
            border-bottom: 1px solid #DCE0EA;
            margin-bottom: 20px;
            text-transform: capitalize; }
        .footerAction {
            margin-top: 30px;
            text-align: right; } }
    .inputBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        label {
            width: 150px;
            margin: 0; }
        .inputWrap, .selectWrap {
            flex: 1; }
        &.dateSelection {
            .inputWrap {
                width: 160px;
                flex: none; } } } }

.projectDetailsPopContent {
    .popupTitle {
        margin-bottom: 40px; }
    .inputBlock.dateSelection {
        .inputWrap {
            width: 160px;
            flex: none; }
        .right {
            margin-left: 70px;
            display: flex;
            align-items: center;
            label {
                width: 40px; } } }
    .footerAction {
        margin-top: 60px; } }

.addFilesPop {
    .footerAction {
        .borderBtn {
            margin-right: 16px; } }
    .fileBrowseSection {
        background: #FAFCFF;
        border: 1px solid #DCE0EA;
        width: 580px;
        height: 164px; } }

.addCustomerPop {
    .inputWrap {
        &.mobileField {
            width: 218px;
            flex: none; } }
    .inputBlock:last-child {
        align-items: flex-start;
        label {
            padding-top: 15px; } } }

.popupForm {
    .child-2,
    .child-3 {
        display: flex;
        justify-content: space-between;
        .inputBlock {
            flex-basis: 48%; } }
    .child-3 {
        .inputBlock:nth-child(2) {
            margin: 0 15px; } }

    .inputBlock {
        display: flex;
        flex-direction: column;
        align-items: normal;
        label {
            @include font(13px, normal, #717394);
            margin-bottom: 13px;
            width: 100%; } } }

.addNewUserPop {
    .footerAction {
        margin-top: 0 !important;
        justify-content: space-between;
        align-items: center;
        .checkboxWrap {
            display: flex;
            align-items: center;
            input {
                width: 15px;
                height: 15px;
                margin-right: 7px; }
            label {
                @include font(13px, normal, #717394);
                cursor: pointer; } } } }

//****************************************************** Create User popup

.userFormWrapper {
    display: grid;
    grid-template-columns: auto auto;
    height: 100%;
    padding: 15px; }

//****************************************************** Add New Customer Popup

.custommerFormWrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 15px; }
