.RegisterWrap {
  .registerLHS {
    width: 450px;
    background: url(../images/loginLHSB-bg.png) no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 55px;
    .logo {
      width: 255px;
      align-self: center;
      flex: 1; }
    .description {
      width: 255px;
      @include font(11px, 15px, #333);
      margin: 0 auto; } }
  .registerRHS {
    width: calc(100% - 450px);
    display: flex;
    align-items: center;
    justify-content: center; } }

.registrationForm {
  width: 444px;
  margin-top: 122px;
  .welcometxt {
    @include font(18px, 23.94px, #000);
    margin-bottom: 43px;
    span {
      font-weight: 700; } }
  .registerFormWrap {
    .firstLastField {
      justify-content: space-between;
      .inputWrap {
        flex-basis: 48%; } }

    .inputWrap {
      margin-bottom: 20px;
      input {
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 100px;
        height: 49px;
        width: 100%;
        padding: 0 0 0 24px;
        &::placeholder {
          @include font(14px, null, #333); }
        &.error {
          input::placeholder {
            color: #E04F5F; } } }
      .inputErrorMsg {
        margin-left: 25px; } }
    .formFooter {
      button {
        background: $link;
        border-radius: 100px;
        height: 49px;
        color: #FFFFFF;
        text-transform: uppercase;
        width: 100%;
        border: 0;
        font-family: roboto;
        font-weight: 700;
        cursor: pointer; }
      .loginLink {
        text-align: center;
        margin-top: 20px;
        @include font(13px, 17.29px, #999);
        a {
          color: $link;
          text-decoration: underline;
          font-weight: 600; } } } }
  .mobileField {
    position: relative;
    select {
      border-radius: 100px 0 0 100px;
      padding: 0 0 0 20px;
      border: 0;
      border-right: 1px solid #ddd;
      top: 0;
      bottom: 0;
      margin: auto; }
    input {
      padding-left: 100px !important; } } }
.passwordField {
  position: relative;
  .passwordEye {
    position: absolute;
    top: 15px;
    right: 22px;
    cursor: pointer;
    svg {
      width: 18px;
      height: 18px; }
    &.active {
      svg > path {
        fill: $link; } } } }

.loginForm {
  .loginTitle {
    @include font(18px, 24px, #000);
    font-weight: bold;
    margin-bottom: 25px; }
  .loginType {
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      li {
        margin-bottom: 20px;
        &:nth-child(odd) {
          margin-right: 17px; } } }
    button {
      @include font(14px, 19px);
      font-weight: 600;
      width: 213px;
      height: 49px;
      border: 0;
      border-radius: 100px;
      box-shadow: 4px 4px 15px #D6D9DF;
      display: flex;
      align-items: center;
      padding: 0 23px;
      cursor: pointer; }
    .FBBtn > button {
      background: #3B5998;
      color: #fff;
      img {
        margin-right: 20px; } }
    .googleBtn > button {
      background: #FFFFFF;
      color: #333;
      img {
        margin-right: 13px; } }
    .officeBtn > button {
      background: #EB3C00;
      color: #fff;
      img {
        margin-right: 16px; } } }
  .orDivider {
    @include font(14px, 19px, #bbb);
    padding: 0 13px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      background: #eee;
      height: 1px;
      width: 45.2%; }
    &::after {
      right: 0; }
    &::before {
      left: 0; } }
  .remember-forgotPass {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 40px;
    .rememberMeWrap {
      display: flex;
      align-items: center;
      cursor: pointer;
      input {
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        margin-right: 10px; }
      label {
        @include font(12px, 16px, #999);
        font-weight: 600;
        margin-top: -1px; } }
    .Forgotpassword {
      @include font(12px, 16px, $link);
      text-decoration: underline;
      cursor: pointer; } } }
