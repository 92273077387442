@font-face {
  font-family: segoe;
  src: url('../font/SegoeUI.woff2') format('woff2'), url('../font/SegoeUI.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'roboto';
  src: url('../font/roboto-medium-webfont.woff2') format('woff2'), url('../font/roboto-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
