body {
  @include font(14px, 16px, #000, $baseFont);
  height: 100%; }
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

div[id=root], html {
  height: 100%; }

a {
  cursor: pointer;
  text-decoration: none;
  color: $link;
  &:hover,
  &:focus, {
    text-decoration: none;
    color: $link;
    outline: none; } }
button[disabled],
html input[disabled] {
  cursor: default;
  color: #CC9999; }
li {
  list-style-type: none; }
.clearfix::after {
  content: "";
  clear: both;
  display: table; }

.form-control {
  height: 40px;
  @include font(16px, 18px, #aaa);
  border: 1px solid #eee;
  box-shadow: none; }

select::-ms-expand {
  display: none; }

.flex-column {
  flex-direction: column; }

.cursorPointer {
  cursor: pointer; }
.hide {
  display: none !important; }
.show {
  display: block; }

.relative {
  position: relative; }

.d-flex {
  display: flex; }
.flex-wrap {
  flex-wrap: wrap; }
.align-center {
  align-items: center; }
.justify-between {
  justify-content: space-between; }
.justify-center {
  justify-content: center !important; }
.center {
  text-align: center; }
.container {
  width: 100%; }

.twoWayArrow {
  width: 8px;
  height: 8px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(45deg); }

// SEARCH
.search {
  position: relative;
  input {
    width: 335px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #D0D5DF;
    background: $white;
    border-radius: 6px;
    padding: 0 14px 0 17px;
    font-size: 13px;
    color: #515266;
    &::placeholder {
      @include font(13px, 17px, #BABBCA); }
    &:focus, .updated {
      border: 1px solid $link !important;
      color: $link; } }
  .searchBtn {
    background: none;
    border: 0;
    position: absolute;
    height: 40px;
    width: 40px;
    right: 0;
    border-radius: 0 6px 6px 0;
    cursor: pointer;
    svg {
      fill: $link;
      width: 16px;
      height: 16px; } } }

// TABLE
.tableWrap {
  background: #FFFFFF;
  box-shadow: 4px 15px 20px #E8EFFC;
  border-radius: 6px;
  padding: 20px;
  margin-top: 24px;
  header {
    align-items: center;
    justify-content: space-between; } }

.block {
  background: $white;
  box-shadow: 4px 15px 20px #E8EFFC;
  border-radius: 6px;
  padding: 24px 20px;
  h2 {
    @include font(16px, 21px, #3E3E4F);
    text-transform: capitalize;
    margin-bottom: 25px; } }

// BUTTON
.btn {
  border-radius: 6px;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 13px;
  font-size: 14px;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  min-width: 120px;
  &:hover, &:focus {
    outline: 0; } }

.orangeBtn {
  background: $link;
  box-shadow: 4px 10px 15px rgba(238, 96, 84, 0.2);
  color: $white; }

.borderBtn {
  border: 1px solid $link;
  color: $link;
  background: $white; }

// INPUT
.inputBlock {
  display: flex;
  flex-direction: column;
  .label, label {
    margin-bottom: 9px;
    color: #717394;
    text-transform: capitalize;
    .requiredField {
      color: #FF0000; } } }

.inputWrap {
  position: relative;
  input,
  textarea {
    background: #FAFCFF;
    border: 1px solid #DCE0EA;
    border-radius: 4px;
    height: 40px;
    @include font(13px, 17px, #3e3e4f);
    width: 90%;
    padding: 0 10px;
    &:focus, &.updated {
      background: #FFFFFF;
      border: 2px solid $link;
      color: $link;
      font-weight: 600;
      &::placeholder {
        color: $link; } }
    &::placeholder {
      color: #CBD4E1; } }
  &.error {
    input,
    textarea {
      background: #FFF3F5 !important;
      border: 1px solid #E04F5F !important;
      color: #E04F5F; } }
  .inputbadge {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    svg {
      fill: $link; } }
  textarea {
    resize: none;
    width: 100%;
    height: 80px;
    padding: 11px 10px; } }

.inputErrorMsg {
  @include font(11px, 15px, #E04F5F);
  text-transform: capitalize; }

.selectWrap {
  position: relative;
  .twoWayArrow {
    display: inline-block;
    border-color: $link;
    position: absolute;
    top: 13px;
    right: 15px; }
  select {
    width: 100%; } }

select {
  border: 1px solid #DCE0EA;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #FAFCFF;
  cursor: pointer;
  width: 190px;
  height: 40px;
  padding: 0 30px 0 10px;
  color: #B8BECC;
  font-weight: 400;
  &:focus {
    border-color: $link;
    color: $link;
    font-weight: 600;
    option {
      color: $black; } } }

.label {
  @include font(13px, 17px, #717394);
  margin-right: 11px;
  text-transform: capitalize;
  .requiredField {
    color: #FF0000; } }

// MOBILE FIELD
.mobileField {
  position: relative;
  select {
    position: absolute;
    height: 38px;
    width: 83px;
    border-radius: 4px 0 0 4px;
    padding: 0 0 0 12px;
    border: 0;
    left: 1px;
    top: 1px;
    border-right: 1px solid #ddd;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../images/selectArrow.png) no-repeat 88% center;
    cursor: pointer; }
  input {
    padding-left: 93px !important; } }

.contentLeft {
  flex: 1; }

.contentRight {
  width: 270px;
  margin-left: 30px; }

.blockFooter {
  display: flex;
  align-items: center;
  justify-content: center; }

.pageRight {
  flex: 1;
  padding: 30px;
  background: #F1F5FD;
  margin-left: 300px;
  min-height: 100vh; }

// full Page Loader

.fullPageLoader {
  height: 250px;
  .overlay {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .loaderInner {
    background: $white;
    border-radius: 4px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    .loadingTxt {
      margin-top: 28px; }
    .messageTxt {
      margin-top: 20px; }
    .loadingTxt,
    .messageTxt {
      @include font(16px, 22px, #B0B0B0, $baseFont);
      text-align: center; } } }

// Accordion
.accordion {
  .accordionItem {
    header {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      background: #F1F5FD; } } }

.breadcrumWrap {
  justify-content: space-between;
  align-items: flex-end;
  .search {
    margin-right: 21px; } }
.breadcrumb {
  @include font(13px, 15px, #717394, roboto);
  display: flex;
  align-items: center;
  li {
    text-transform: capitalize;
    &:not(:last-child) {
      background: url(../images/ArrowRight.svg) no-repeat right 0 center;
      padding-right: 25px;
      margin-right: 10px; } } }
.greenBtn {
  padding: 0 17px;
  border: 1px solid #78cf4d;
  background: #fff;
  color: #78cf4d;
  &:hover {
    background: #78cf4d;
    color: #fff; } }

.uploadBtn {
  min-width: 91px;
  margin-right: 20px; }

.tabsWrap {
  border-bottom: 1px solid #E2E8F6;
  display: flex;
  margin-bottom: 20px;
  .tabItem {
    @include font(16px, 19px, #717394, roboto);
    padding-bottom: 10px;
    position: relative;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 40px; }
    &.active,
    &:hover {
      color: #EE6054;
      font-weight: 700;
      &::after {
        content: '';
        position: absolute;
        bottom: -1.5px;
        width: 100%;
        height: 3px;
        background: #EE6054;
        left: 0; } } } }
.Tabs-filled {
  .tabsFilledItem {
    background: $white;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 176px;
    cursor: pointer;
    margin-bottom: 2px;
    &:not(:last-child) {
      margin-right: 3px; }
    @include font(14px, 16px, #22A4EE, roboto);
    &:first-child {
      border-radius: 6px 0 0 0; }
    &:last-child {
      border-radius: 0 6px 0 0; }
    &.active,
    &:hover {
      background: #22A4EE;
      color: $white; } } }
.Tabs-filledContent {
  .tableWrap {
    margin: 0; } }
.ratesContent,
.prioritiesContent,
.deadlinesContent {
  .breadcrumWrap {
    align-items: center; } }
.createNewPriBtn {
  margin-bottom: 20px; }
.justify-end {
  justify-content: flex-end; }
.domainTableList,
.prioritiesTableList,
.EntitiUserTable,
.EntitiCustomerTable,
.EntitiVendorTable {
  header {
    display: none; }
  table {
    margin: 0; } }
.uploadFileWrap {
  width: 580px;
  height: 150px;
  position: relative;
  border: 1px solid #78CF4D;
  border-radius: 4px;
  background: rgba(120, 207, 77, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  .fileTxt {
    @include font(20px, 27px, #78cf4d);
    font-weight: 700; }
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    opacity: 0;
    cursor: pointer; } }

.tabsWrap {
  padding-top: 20px;
  .tabItem {
    @include font(16px, 19px, #717394, roboto);
    padding-bottom: 10px;
    position: relative;
    cursor: pointer;
    background: none;
    &:not(:last-child) {
      margin-right: 40px; }
    &.active,
    &:hover {
      color: #EE6054;
      font-weight: 700;
      &::after {
        content: '';
        position: absolute;
        bottom: -1.5px;
        width: 100%;
        height: 3px;
        background: #EE6054;
        left: 0; } } } }

.editBtnWraper {
  display: flex;
  align-items: center;
  justify-content: center;
  .editBtn {
    color: #22A4EE;
    background: #ffffff;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding-left: 4px;
    &:hover {
      border-bottom: 1px solid #22A4EE; } } }
.deleteBtn {
  background: #ffffff;
  border: none;
  cursor: pointer; }

.rateBtn {
  color: #22A4EE;
  font-weight: bold;
  background: #E9F6FD;
  padding: 3px 8px 3px 8px;
  border-radius: 2px;
  border: 1px solid #22A4EE;
  cursor: pointer; }

.textFieldContainer {
  padding: 0px 10px 10px 10px; }
.titieWrapper {
  padding-bottom: 10px; }
.title {
  font-size: 13px;
  color: #717394; }
.requiredField {
  color: #EE6054;
  padding-left: 5px; }
.inputContainer {
  display: flex;
  flex-direction: column;
  input {
    background: #FAFCFF;
    border: 1px solid #DCE0EA;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    padding: 10px; } }

input:focus {
  border: 1px solid #22A4EE; }

input::placeholder {
  color: #C4C6C8;
  font-size: 13px; }
input:focus::placeholder {
  color: #22A4EE; }

.errorText {
  padding-top: 5px;
  color: #EE6054;
  font-size: 12px; }

.bottomButtonSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px; }
.checkBoxContainer {
  display: flex;
  align-items: center; }
.checkboxStyle {
  margin-right: 10px;
  label {
    font-size: 13px;
    color: #717394; } }

.domainBtn {
  background: #34C6C3;
  border: none;
  padding: 15px;
  border-radius: 5px;
  color: #ffffff;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer; }
