.tableContainer {
    background: white;
    //color: white
    height: 300px;
    width: 100%;
    display: inline-block;
    overflow: scroll; }
.tableTopHeader {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #E2E8F6;
    width: 100%; }

.table-btn {
    background: #E2E8F6;
    border: none;
    padding: 8px;
    font-size: 12px;
    border-right: 2px solid #ffffff;

    &:hover {
        background: #606E8D;
        color: white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        cursor: pointer; } }

::-webkit-scrollbar {
    width: 4px;
    height: 4px; }

::-webkit-scrollbar-track {
    background: #f1f1f1; }

::-webkit-scrollbar-thumb {
    background: #ee6054;
    border-radius: 20px; }

.fileListTable {
    background: #ffffff;
    margin-top: 20px;
    width: 100%; }

.previewBtn {
    background: #ffffff;
    border: none;
    border-bottom: 2px solid #EE6054;
    color: #EE6054;
    font-weight: bold;
    cursor: pointer; }

.uploadBtb {
    background: #E9F6FD;
    color: #22A4EE;
    border: 1px solid #22A4EE;
    padding: 3px;
    border-radius: 2px;
    width: 100%; }

.downloadBtn {
    background: #EBF9F9;
    color: #34C6C3;
    border: 1px solid #22A4EE;
    padding: 3px;
    border-radius: 2px;
    width: 100%;
    cursor: pointer; }

.redTable-btn {
    background: #E2E8F6;
    border: none;
    padding: 8px;
    font-size: 12px;
    color: #EE6054;
    border-right: 2px solid #ffffff;
    &:hover {
        background: #EE6054;
        color: white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        cursor: pointer; } }

.greeTable-btn {
    background: #E2E8F6;
    border: none;
    padding: 8px;
    font-size: 12px;
    color: #57B12A;
    border-right: 2px solid #ffffff;

    &:hover {
        background: #57B12A;
        color: white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        cursor: pointer; } }

.tableBottomHeader {
    padding: 20px;
    font-weight: bold;
    font-size: 15px; }

.text {
    padding-right: 10px;
    font-weight: bolder; }

.audioPlayer {
    .rhap_total-time {
        color: #ffffff; }
    .rhap_current-time {
        color: #ffffff; }
    .rhap_repeat-button {
        color: #ffffff; }
    .rhap_forward-button {
        color: #ffffff; }
    .rhap_rewind-button {
        color: #ffffff; }
    .rhap_play-pause-button {
        color: #ffffff; }
    .rhap_volume-button {
        color: #ffffff; }
    .rhap_volume-bar {
        background: #ffffff;
        .rhap_volume-indicator {
            background: #ffffff; } }
    .rhap_progress-bar {
        .rhap_download-progress {
            background: #740AF6;
            opacity: 0.2; } }
    .rhap_progress-indicator {
        background: #ffffff; }
    .rhap_progress-filled {
        background: #ffffff; } }

.flexBox {
    display: "flex";
    .submitButton {
        color: #ffffff;
        background: #740AF6;
        border-radius: 5px;
        border: none;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 10px;
        padding-top: 10px;
        border-radius: 5px\; } }

.fileDownloadBtn {
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    border: 2px solid #DCE0EA;
    color: #7C7F85;
    font-weight: bolder;
    background: #FAFCFF;
    &:hover {
        background: #740AF6;
        color: #ffffff;
        border: 2px solid #740AF6;
        cursor: pointer; } }

.inputWrapper {
    display: block; }
