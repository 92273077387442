.contentRight {}

.orderRow {
  border-top: 1px solid #EEF1F6;
  padding: 15px 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    border-bottom: 1px solid #EEF1F6; }
  .label {
    @include font(13px, normal, #717394); }
  .value {
    color: #3E3E4F;
    font-weight: 700; } }

.currencyRow {
  padding: 15px 0 20px;
  .label {
    margin-bottom: 13px; }
  select {
    width: 100%;
    margin: 0; } }

.payableRow {
  background: #4E5D7D;
  padding: 20px;
  margin: 0 -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label {
    @include font(13px, normal, $white); }
  .PayableValue {
    @include font(22px, normal, $link);
    font-weight: 700; } }
.rightAlignRow {
  text-align: right;
  padding: 15px 0 12px;
  @include font(13px, normal);
  .ETALabel {
    margin: 0; }
  .value {
    font-weight: 700; }
  &.border-btm {
    border-bottom: 1px solid #ccc; } }

.orderPlace button {
  margin: 27px auto 0;
  width: 100%; }
