.paginationWrap {
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  .showpages {
    width: 100px;
    height: 30px;
    margin-right: 10px; }
  .inputWrap {
    input[type="number"] {
      height: 30px;
      width: 60px;
      margin-left: 9px; } }

  .paginationAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      color: $black;
      padding: 0 5px;
      border: 0;
      cursor: pointer;
      background: none;
      &:hover {
        color: $link; }
      &:disabled {
        opacity: 0.5;
        pointer-events: none; }
      &.prevBtn {
        margin-right: 7px;
        border-right: 1px solid #ccc;
        padding-right: 12px; } } } }
