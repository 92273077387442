.projectContainer {
  .breadcrumWrap {
    display: flex;
    justify-content: space-between; }
  .userSection {
    margin-bottom: 20px; }
  .projectContent {
    .tableWrap {
      margin-top: 20px;
      header {
        display: none; }
      table {
        margin-top: 0; } } } }
