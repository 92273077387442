.leftSideBar {
  width: 300px;
  background: #FBFCFF;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .LHSLogo {
    width: 185px;
    height: 40px;
    margin: 55px 0 45px 58px; }
  .navigationWrap {
    padding: 0 10px; }
  .navItem {
    cursor: pointer;
    border-bottom: 1px solid #F0F5FD;
    .navLabel {
      @include font(14px, 50px, #3E3E4F);
      padding: 0 0 0 40px;
      text-transform: capitalize; }
    .subMenu {
      background: #F1F5FD;
      padding: 27px 0 27px 40px;
      // display: none
      .subMenuItem {
        svg {
          width: 9px;
          height: 8px;
          margin: 0; }
        span {
          margin-left: 12px;
          @include font(13px, normal, #717394); }
        &:active, &:hover {
          font-weight: 700; }
        &:not(:last-child) {
          margin-bottom: 23px; } } }

    &.active,
    &:hover {
      .navLabel {
        background: #E6ECF9; }
      svg {
        fill: $link; }
      .navLabel {
        color: $link;
        font-weight: 700; }
      // .subMenu
 } }      //   display: block
  .logoutLink {
    width: 280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-left: 60px;
    cursor: pointer;
    @include font(12px, 50px, #717394);
    svg {
      margin-right: 7px;
      fill: #717394;
      width: 24px;
      height: 24px; }
    &:hover {
      background: #E6ECF9;
      color: $link;
      svg {
        fill: $link; } } } }
